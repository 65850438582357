import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from '@emotion/styled';
import Img from 'gatsby-image';
import ButtonLink from './ButtonLink';
import IcomoonIcon from './IcomoonIcon';
import { color, breakpoints, border, area } from '../../utils/style';
import { getLocalFile } from '../../utils/system';
import Modal, { useModalOpen } from './Modal';

// images
import play from '../../img/play.svg';

const Wrapper = styled('div')`
  flex-direction: column;
  display: flex;
  height: 394px;
  width: 100%;
  border-radius: ${border.borderRadius4};
  margin-left: 20px;
  padding: ${area.blockPadding};
  font-size: 1rem;
  @media (max-width: ${breakpoints.md}) {
    width: 100%;
    margin-left: 0;
    @media (max-height: ${breakpoints.tl}) {
      @media (min-height: ${breakpoints.md}) {
        padding: 40px 0 0 0;
      }
    }
  }
  @media (max-width: ${breakpoints.xs}) {
    margin-bottom: 0;
  }

  .h3 {
    font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
    font-size: 26px;
    font-weight: bold;
    color: ${color.lightningYellow};
    margin: 0 0 20px 0;
    display: flex;
    align-items: center;

    & img.iconImage {
      display: inline-block;
    }

    @media (max-width: ${breakpoints.md}) {
      font-size: 22px;
    }
    img {
      width: 35px;
      margin-right: 12px;
    }
  }

  .name {
    color: ${color.waikawaGray};
    padding-top: 15px;
  }

  .title {
    margin: 2px 0 4px 0;
    color: ${color.white};
    font-weight: 500;
  }

  .button {
    text-align: left;
    font-size: 0.9rem;
    text-transform: uppercase;
    display: flex;
    justify-content: start;
    align-items: center;
  }
`;

const ImgWrap = styled('div')`
  position: relative;
  //border-radius: 8px;
  overflow: hidden;

  .play-button {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    margin: -25px 0 0 -25px;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    opacity: 0.8;
    transition-duration: 0.5s;
    color: transparent;
    &:hover,
    &:active {
      svg {
        path {
          fill: ${color.orange} !important;
        }
      }
    }
  }

  &:hover .play-button {
    opacity: 1;
  }

  & img {
    display: block !important;
  }
`;

const Video = ({ iframeOptions = {} }) => {
  const togglePopup = useModalOpen('home-video-popup');

  const data = useStaticQuery(graphql`
    {
      allWordpressPage(filter: { slug: { eq: "home" } }) {
        edges {
          node {
            acf {
              link_all_game
              video_game_link
              video_game_title
              video_game_cover {
                alt_text
                title
                acf {
                  slide_url
                  slide_title
                  slide_description
                }
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 500) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const { allWordpressPage: { edges = [{}] } = {} } = data;
  const {
    node: {
      acf: {
        link_all_game: linkAllGames,
        video_game_link: videoLink,
        video_game_title: videoTitle,
        video_game_cover: { localFile, alt_text: imgAltText, title: imgName, acf: { slide_title: imgTitle } = {} } = {},
      } = {},
    } = {},
  } = edges[0];

  return (
    <Wrapper id="video">
      <div className="h3">
        <img className="iconImage" src={play} alt="play" />
        {videoTitle}
      </div>
      <ImgWrap>
        {videoLink && (
          <button type="button" className="play-button" onClick={togglePopup}>
            <IcomoonIcon icon="play-button" size={45} />
          </button>
        )}
        <Img fluid={getLocalFile(localFile)} alt={imgAltText} />
      </ImgWrap>
      <span className="name">{imgName}</span>
      <span className="title">{imgTitle}</span>
      {linkAllGames && (
        <ButtonLink
          to={linkAllGames}
          text="อ่านเพิ่มเติม"
          ariaText="อ่านเพิ่มเติม"
          className="button withoutBgr color-yellow"
          iconColor={color.lightningYellow}
          iconSize="15"
        />
      )}
      <Modal title="Video Game" popupId="home-video-popup">
        <iframe title="Video Game" src={videoLink} {...iframeOptions} />
      </Modal>
    </Wrapper>
  );
};
export default Video;
