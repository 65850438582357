import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import IcomoonIcon from './IcomoonIcon';
import { color } from '../../utils/style';

const Wrapper = styled('button')`
  span {
    display: inline-block;
    vertical-align: top;
    line-height: 1.2;
    color: ${color.white};
  }
  &.left-icon {
    svg {
      margin-right: 10px;
      margin-left: 0;
    }
  }
`;

const Button = ({ text, className, iconName, iconLeft, iconSize, customHandler, ariaText, iconColor }) => {
  const classes = iconLeft ? `${className} left-icon` : className;
  return (
    <Wrapper className={classes} onClick={customHandler} aria-label={ariaText}>
      {iconName && iconLeft && <IcomoonIcon icon={iconName} color={iconColor} size={iconSize} alt="button" />}
      <span>{text}</span>
      {iconName && !iconLeft && <IcomoonIcon icon={iconName} color={iconColor} size={iconSize} alt="button" />}
    </Wrapper>
  );
};

Button.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  iconName: PropTypes.string,
  iconLeft: PropTypes.bool,
  iconSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  customHandler: PropTypes.func,
  ariaText: PropTypes.string,
  iconColor: PropTypes.string,
};

Button.defaultProps = {
  className: 'button',
  iconName: 'arrow-right',
  iconLeft: false,
  iconSize: 18,
  iconColor: color.white,
  ariaText: '',
  customHandler: () => false,
  text: '',
};

export default Button;
