import React, { useCallback, useState } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from '@emotion/styled';
import Button from './Button';
import { color, border, area, breakpoints, sizing, font } from '../../utils/style';
import football from '../../img/football.svg';

const SeoContent = () => {
  const Bg = styled('div')`
    border-radius: ${border.borderRadius4};
    position: relative;
    padding: ${area.blockPadding};
    img {
      position: absolute;
      max-width: 40px;
    }

    h2 {
      margin-left: 50px;

      @media (max-width: ${breakpoints.md}) {
        margin-top: 0;
        font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
        font-size: 22px;
        font-weight: bold;
        line-height: normal;
        color: ${color.lightningYellow};
      }
      @media (max-width: ${breakpoints.tl}) {
        font-size: 22px !important;
      }
    }
    
    p {
      margin: 10px 0;
      text-align: initial;
    }

    & > div {
      padding-bottom: 30px;
    }

    img {
      @media (max-width: ${breakpoints.tl}) and (orientation: portrait) {
        width: 45px;
        max-width: 45px;
      }
      @media (max-width: ${breakpoints.tl}) and (orientation: landscape) {
        top: 25px;
      }

      @media (max-width: ${breakpoints.sm}) and (orientation: portrait) {
        top: 40px;
      }

      @media (max-width: ${breakpoints.xs}) and (orientation: portrait) {
        top: 40px;
      }
    }

    .compress {
      height: 270px;
      overflow: hidden;
      margin-bottom: 10px;
      p {
        margin-bottom: 26px;
        font-size: ${sizing.text};
      }
      h2 {
        color: ${color.lightningYellow};
        font-size: ${sizing.heading};
        font-weight: bold;
        margin-bottom: 15px;
        margin-top: .2em;
      }

      @media (max-width: ${breakpoints.sm}) {
        height: 350px;
      }
    }

    .button {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 133px;
      height: 42px;
      border-radius: ${border.borderRadius14};
      border: solid 1px ${color.lightGrey};
      font-family: ${font.default};
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      color: ${color.lightGrey};
      line-height: 1.2rem;
      outline: none;
      opacity: 0.8;

      &:hover {
        opacity: 1;
      }

      a {
        padding: 10px 20px;
      }
      span {
        font-size: 16px;
        color: ${color.lightGrey};
      }
      &.rotate {
        svg {
          transform: rotate(180deg);
        }
        p {
          font-size: ${sizing.text};
        }
      }

      @media (max-width: ${breakpoints.sm}) {
        margin-top: 30px;
        border-radius: ${border.borderRadius8}
      }
    }
  `;
  const [isCompress, setIsCompress] = useState('compress');
  const readMore = useCallback(() => {
    setIsCompress(!isCompress);
  }, [isCompress]);
  return (
    <StaticQuery
      query={graphql`
        {
          allWordpressPage(filter: { slug: { eq: "home" } }) {
            edges {
              node {
                acf {
                  seo_content
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const content = data.allWordpressPage.edges[0].node.acf.seo_content;
        return (
          <Bg id="seo-content">
            <img className="iconImage" src={football} alt="football" />
            <div className={isCompress ? 'compress' : 'rotate'} dangerouslySetInnerHTML={{ __html: content }} />
            <Button
              text={isCompress ? 'อ่านเพิ่มเติม' : 'อ่านน้อยลง'}
              ariaText="อ่านเพิ่มเติม"
              className={isCompress ? 'button withBorder' : 'button withBorder rotate'}
              iconName="arrow-down-simple"
              iconColor={color.lightGrey}
              iconSize="15"
              customHandler={readMore}
            />
          </Bg>
        );
      }}
    />
  );
};

export default SeoContent;
